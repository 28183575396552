<template>
  <div class="inner-layout-800 my-190">
    <div class="memberP-page-title">
      고객님의 계정은<br>
      현재 <span class="color-1">휴면 상태</span>입니다.
    </div>
    <div class="ta-center">

      <p class="fs-14">회원님의 계정이 1년간 로그인하지 않아 휴면 계정으로 전환되었습니다.<br>
        [휴면 상태 해제] 버튼을 통해 휴면 해제 후 서비스 이용이 가능합니다.</p>

      <p class="mt-20">휴면 전환일 : {{ date }}</p>
    </div>

    <div class="mt-25 w-400 mx-auto" @click="release">
      <button class="btn btn-x-large color-1 btn-block">휴면 상태 해제</button>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "sleep",
  data() {
    return {
      date: null,
    }
  },
  created() {
    this.setSleepJwtInfo()
  },
  methods:{
    ...mapActions({
      setSleepToken: 'session/setSleepToken',
    }),
    setSleepJwtInfo() {
      const sleepToken = localStorage.getItem('sleep')
      const info = this.$parseJwt(sleepToken)
      if (info) {
        this.setSleepToken({ sleepToken: sleepToken })
        this.date = info.date && this.$getDateFormat(info.date, 'Y.m.d')
      }
      else {
        location.href = "/login"
      }
    },
    async release() {
      const { result, data } = await this.$api.sleepOff();
      if (result === 'success') {
        const info = this.$parseJwt(data.token)
        if (info) {
          localStorage.setItem('auth', data.token)
          localStorage.setItem('id', info.id)
          localStorage.setItem('email', info.email)
          localStorage.setItem('name', info.name)
          localStorage.setItem('url', info.url ? info.url : '')
          localStorage.setItem('target', info.target)
          localStorage.setItem('phone', info.phone)
        }
        location.href = "/"
      }
    },
  }
}
</script>
